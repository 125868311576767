<template>
  <div />
</template>

<script>
export default {
  created() {
    this.$router.push({ name: "PageNotFound" });
  }
};
</script>
